<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Cadastrar novo professor"
      :enableClose="false"
    >
      <form class="block__form"
        @submit.prevent="salvar()">
        <div v-if="showInstituicaoInfo" class="form-group">
          <label for="instituicao">Instituição</label>
          <select
            id="instituicao"
            v-model="item.instituicaoSelecionada"
            class="form-control"
          >
            <option
              v-for="instituicao in instituicoes"
              :key="instituicao.id"
              :value="instituicao.id"
            >
              {{ instituicao.nome }}
            </option>
          </select>
        </div>

        <div class="row">

          <div class="col-md-6">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input
                id="nome"
                v-model="item.nome"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="item.email"
                type="email"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="telefone">Telefone</label>
              <the-mask
                id="telefone"
                v-model="item.telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="senha">Senha de Acesso</label>
              <input
                id="senha"
                v-model="item.senha"
                type="password"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <hr class="full-hr" />
        <div class="row">
          <div class="col-sm-12">
            <div class="float-right">
              <button
                type="submit"
                class="btn btn-classic"
                :disabled="!formIsValid"
              >
                Salvar
              </button>
              <button
                type="button"
                class="btn btn-secondary m-2"
                @click="showModalCreate = false"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Professores</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Professores
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-7">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                      <input
                        :value="item"
                        name="product"
                        type="checkbox"
                        @change="checkAll($event)"
                        style="margin-top: 15px"
                      />
                      <a @click.prevent="deleteSelected"
                        ><img
                          src="../../../assets/images/trash.svg"
                          style="margin-top: 10px"
                      /></a>
                    </div>
                  </form>
                </div>

                <div class="col-md-1"></div>
                <div class="col-md-2 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-outline"
                        @click.prevent="trashRoute"
                      >
                        LIXEIRA
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2 d-flex">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        style="width: 100%"
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="cole"></th>
                    <th scope="col">Instituição</th>
                    <th scope="col" style="text-align: left">Nome</th>
                    <th scope="col" style="text-align: left">Email</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in filteredRows" :key="item.id">
                    <td>
                      <input
                        :id="item.id"
                        :value="item"
                        name="product"
                        type="checkbox"
                        v-model="checkedProfessores"
                        v-if="!checkAllProf"
                      />
                      <input
                        v-if="checkAllProf"
                        :value="item"
                        name="product"
                        type="checkbox"
                        :checked="true"
                      />
                      <label :for="item.id"><span></span></label>
                    </td>
                    <td class="text-left pl-4" v-html="item.instituicao.nome" />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.nome)"
                    />
                    <td class="text-left pl-4" v-html="item.usuario.email" />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="editar(index)"
                      >
                        <i class="far fa-edit" />
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
import store from "@/store";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      isEditing: false,
      showInstituicaoInfo: true,
      showLeftMenu: true,
      showModalCreate: false,
      id: null,
      checkedProfessores: [],
      checkAllProf: false,
      index: -1,
      item: {
        id: null,
        nome: "",
        email: "",
        telefone: "",
        senha: "",
        instituicao: null,
        instituicaoSelecionada: null,
      },
      items: [],
      filter: "",
      instituicoes: [],
      title: "",
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (
        !this.item.nome ||
        this.item.instituicaoSelecionada == "" ||
        !this.item.email ||
        !this.item.telefone ||
        (!this.item.senha && !this.isEditing)
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });

    this.loadData();

    if (
      checkUserHaveRole([
        constantes.ROLE_SUPER_ADMIN,
        constantes.ROLE_CLIENTE_ADMIN,
      ])
    ) {
      this.loadInstituicoes();
    } else {
      this.showInstituicaoInfo = false;
    }
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    openModalCreate() {
      this.isEditing = false;
      this.showModalCreate = true;
      this.item = {};
      this.index = -1;
      this.item.instituicao = null;
      this.title = "Cadastrar novo professor";
    },
    salvar() {
      this.saveServer();
    },
    deletar(index) {
      console.log(index);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção do professor(a)?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$root.$emit("Spinner::show");
            this.$api
              .put("professors/" + index + "/trash", {})
              .then((d) => {
                console.log(d);
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Professor(a) removido com sucesso!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.items.splice(index, 1);
                this.loadData();
              })
              .catch((error) => {
                console.log(error);
                this.$root.$emit("Spinner::hide");
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Ocorreu um erro.",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        })
        .catch(() => {
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // this.items.splice(index, 1);
    },
    editar(index) {
      this.isEditing = true;
      this.index = index;
      this.item = this.items[index];
      this.item.instituicaoSelecionada = this.item.instituicao.id;
      this.item.email = this.item.usuario.email;
      this.showModalCreate = true;
      this.title = "Editar Professor";
    },
    loadInstituicoes() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("instituicao_ensinos/todas")
        .then((response) => {
          console.log(response);
          response.data.forEach((res) => {
            if (res.trashAt == null) {
              this.instituicoes.push(res);
            }
          });
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
    trashRoute() {
      this.$router.push("professoresTrash");
    },

    loadData() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api.get("professors").then((response) => {
        console.log(response);
        this.items = response.data.filter(prof => 
          prof.trashAt == null && 
          (prof.instituicao.id == idInstituicao || idInstituicao == 0)
        );

        this.$root.$emit("Spinner::hide");
      });
    },
    deleteSelected() {
      console.log(this.checkedProfessores);
      this.checkedProfessores.forEach((data) => {
        // this.deletar(data.id);
        this.$api.put("professors/" + data.id + "/trash", {}).then((res) => {
          this.loadData();
        });
      });
    },
    checkAll(e) {
      console.log(e.target.checked);
      if (e.target.checked == true) {
        this.checkAllProf = true;
        this.items.forEach((data) => {
          this.checkedProfessores.push(data.id);
        });
      } else {
        this.checkAllProf = false;
        this.checkedProfessores = [];
      }
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      if (this.item.instituicaoSelecionada == undefined) {
        this.item.instituicao = localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO);
      } else {
        this.item.instituicao = this.item.instituicaoSelecionada;
      }

      if (this.index >= 0) {
        this.$api
          .put("professors-edit/" + this.item.id, this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Professor editado com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              // this.loadData();
              // this.showModalCreate = false;
            });
            setTimeout(function () {
              this.showModalCreate = false;
              location.reload();
            }, 1000);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        // const fs = require("fs");
        // fs.readFileSync = () => {
        //   console.log("fs.readFileSync stub called");
        // };
        // var Mailgun = require("mailgun-js");

        console.log(this.item);
        this.$api
          .post("professors-add", this.item)
          .then((res) => {
            console.log(res);
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Professor cadastrado com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              // this.loadData();
              // this.showModalCreate = false;
            });
            setTimeout(function () {
              this.showModalCreate = false;
              location.reload();
            }, 1000);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            console.log(error.response.data);
            Swal.fire({
              position: "center",
              icon: "error",
              // title: '<span style="font-size: 20px;">' + error.response.data.violations[0].message + '</span>',
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          });
      }
    },
  },
};
</script>
